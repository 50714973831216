.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.error-message {
  color: red
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CardFloatIcon-contain {
  overflow: inherit !important;
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
}

.CardFloatIcon {
  float: left;
  padding: 15px;
  margin-top: -20px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #999;
  background: linear-gradient(60deg, #26c6da, #00acc1);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 172, 193, .4);
}

.CardFloatTitle-contain {
  overflow: inherit !important;
  padding-left: 20px;
  padding-right: 20px;
}

.CardFloatTitle {
  float: left;
  width: 100%;
  padding: 15px;
  margin-top: -20px;
  border-radius: 3px;
  background: linear-gradient(60deg, #26c6da, #00acc1);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 172, 193, .4);
  color: #FFF;
  padding: 0;
  position: relative;
  z-index: 3 !important;
  border-bottom: none;
}

.MapModal {
  width: 100%;
  height: 100%;
  background-color: white;
}

.fabCenter {
  position: fixed !important;
  border-bottom: 20px !important;
  z-index: 1000;
  left: 20px
}

.fabLocation {
  position: fixed !important;
  top: 67px !important;
  z-index: 1000;
  right: 20px;
}

.fabFilter-container  {
  padding: 30px;
}

.fabFilter  {
  position: fixed !important;
  top: 90px !important;
  z-index: 1000;
  right: 20px;
}

.IndexStepper {
  position: fixed !important;
  bottom: 20px !important;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 1px 20px rgba(0, 0, 0, .1);
  max-width: 350px;
}


.IndexStepp-order {
  height: 100%;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.SwitchSeeClient {
  position: absolute;
  right: 0;
}